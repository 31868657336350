(function () {
  'use strict';

  angular
  .module('neo.authentication')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('authentication', {
      abstract: true,
      templateUrl: 'authentication/authentication.tpl.html'
    })
    .state('authentication.userConfirmation', {
      url: '^/confirm?token',
      controller: 'ConfirmationCtrl',
      controllerAs: 'vm',
      resolve: {
        tokenConfirmation: tokenConfirmation
      }
    });

    function tokenConfirmation(Tokens, $stateParams, $q) {
      var deferred = $q.defer();

      Tokens.get({token: $stateParams.token},
        function () {
          // returned 204
          deferred.resolve('valid');
        },
        function (response) {
          // returned 404
          if (response.status === 404) {
            deferred.resolve('invalid');
          } else {
            deferred.reject(response);
          }
        });

      return deferred.promise;
    }
  }
}());
